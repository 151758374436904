import React from "react";
import { Link } from "gatsby";

import { useAuth } from "../concerns/auth";

import routes from "../../clhbid-routes";

import SocialIcons from "./SocialIcons";
import ContactInformation from "./ContactInformation";

import logo from "../images/clhbid-logo-with-clhlaw.png";

const menus = [
  {
    title: `Why CLHbid.com`,
    path: `/why-clhbid/`,
    links: [
      {
        path: "/questions/selling-farmland/how-does-clhbid-com-compare-to-the-alternatives/#CLHbid.com-vs.-Private-Sale",
        title: "Private Sale",
      },
      {
        path: "/questions/selling-farmland/how-does-clhbid-com-compare-to-the-alternatives/#CLHbid.com-vs.-Public-Auction",
        title: "Public Auction",
      },
      {
        path: "/questions/selling-farmland/how-does-clhbid-com-compare-to-the-alternatives/#CLHbid.com-vs.-Private-Tender",
        title: "Private Tender",
      },
      {
        path: "/questions/selling-farmland/how-does-clhbid-com-compare-to-the-alternatives/#CLHbid.com-vs.-Professional-Realtor",
        title: "Professional Realtor",
      },
      {
        title: "Testimonials",
        path: "/testimonials/",
      },
    ],
  },
  {
    title: `How We Work`,
    path: `/how-clhbid-works/`,
    links: [
      {
        path: "/questions/sale-process/how-does-the-bidding-process-work/#Bidding-Process",
        title: "Bidding Process",
      },
      {
        path: "/questions/sale-process/how-does-the-bidding-process-work/#Starting-Bids",
        title: "Starting Bids",
      },
      {
        path: "/questions/sale-process/how-does-the-bidding-process-work/#Closing-The-Sale",
        title: "Closing the Sale",
      },
      {
        path: "/questions/sale-process/what-is-en-bloc-and-how-does-it-work/",
        title: "En Bloc Process",
      },
      {
        path: "/questions/buying-farmland/what-is-the-transaction-fee/",
        title: "Transaction Fee",
      },
      {
        title: `Terms and Conditions`,
        path: `/terms-and-conditions/`,
      },
    ],
  },
  {
    title: "Account",
    links: [
      {
        title: `Login`,
        path: routes.login(),
      },
      {
        title: `Create an Account`,
        path: routes.register(),
      },
      {
        title: `Contact Us`,
        path: `/contact/`,
      },
    ],
    loggedLinks: [
      {
        title: `View Account`,
        path: routes.profile(),
      },
      {
        title: `Logout`,
        path: routes.logout(),
      },
      {
        title: `Contact Us`,
        path: `/contact/`,
      },
    ],
  },
];

const setLink: React.FC<{
  title: string;
  path: string;
}> = (link) => {
  const className =
    "block text-clhbid-orange mb-2 hover:text-clhbid-orange-dark transition-colors duration-300";

  return link.path.startsWith("https://") ? (
    <a key={link.title} href={link.path} className={className}>
      {link.title}
    </a>
  ) : (
    <Link key={link.title} to={link.path} className={className}>
      {link.title}
    </Link>
  );
};

const Footer: React.FC = () => {
  const { state } = useAuth();

  return (
    <footer className="relative bg-clhbid-gray w-full flex flex-col align-center">
      <div className="w-full flex justify-center">
        <nav className="flex-grow flex flex-col md:flex-row justify-between max-w-screen-xl w-screen-xl px-3 py-4 text-lg md:py-6">
          <div className="mb-10">
            <img src={logo} alt="CLHbid.com logo" className="w-48 mb-6" />
            <ContactInformation />
            <SocialIcons />
          </div>
          {menus.map((menu) => (
            <section key={menu.title} className="mb-6">
              <h3 className="text-2xl mb-3 md:mb-6">
                {menu.path ? (
                  <Link to={menu.path}>{menu.title}</Link>
                ) : (
                  menu.title
                )}
              </h3>
              {menu.title !== "Account" && menu.links.map(setLink)}

              {menu.title === "Account" &&
                state === "authenticated" &&
                menu.loggedLinks.map(setLink)}

              {menu.title === "Account" &&
                state !== "authenticated" &&
                menu.links.map(setLink)}
            </section>
          ))}
        </nav>
      </div>
      <div className="text-center m-2 md:m-8">
        © Copyright CLHbid.com, a division of CLHlaw | All Rights Reserved.
      </div>
    </footer>
  );
};

export default Footer;
